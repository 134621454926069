.hero {
  position: relative;
  background-color: black; /* Adjust as needed */
}

/* .transparent-text { */
/* background-image: linear-gradient(
    to bottom right,
    #098db0,
    #098db0,
    #6c499e,
    #f28b45,
    #c21340,
    #c21340
  );
  background-clip: text;
  -webkit-background-clip: text; /* For Safari */
/* color: transparent; */
/* } */

.transparent-text {
  background-image: linear-gradient(
    to bottom right,
    #098db0,
    #098db0,
    #6c499e,
    #f28b45,
    #c21340,
    #c21340
  );
  background-size: 300% 300%; /* Increase size to allow for a smoother transition */
  background-clip: text;
  -webkit-background-clip: text; /* For Safari */
  color: transparent;
  animation: rotateColors 5s ease-in-out infinite;
}

@keyframes rotateColors {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.floatings {
  position: absolute;
  top: 20%;
  left: 70%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.anton-regular {
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* From Uiverse.io by Shoh2008 */
.loader {
  width: 106px;
  height: 56px;
  display: block;
  margin: 30px auto;
  background-image: linear-gradient(#e1e1e1 50px, transparent 0),
    linear-gradient(#e1e1e1 50px, transparent 0),
    linear-gradient(#e1e1e1 50px, transparent 0),
    linear-gradient(#e1e1e1 50px, transparent 0),
    radial-gradient(circle 14px, #e1e1e1 100%, transparent 0);
  background-size: 48px 15px, 15px 35px, 15px 35px, 25px 15px, 28px 28px;
  background-position: 25px 5px, 58px 20px, 25px 17px, 2px 37px, 76px 0px;
  background-repeat: no-repeat;
  position: relative;
  transform: rotate(-45deg);
  box-sizing: border-box;
}

.loader::after,
.loader::before {
  content: "";
  position: absolute;
  width: 56px;
  height: 56px;
  border: 6px solid #e1e1e1;
  border-radius: 50%;
  left: -45px;
  top: -10px;
  background-repeat: no-repeat;
  background-image: linear-gradient(#e1e1e1 64px, transparent 0),
    linear-gradient(#e1e1e1 66px, transparent 0),
    radial-gradient(circle 4px, #e1e1e1 100%, transparent 0);
  background-size: 40px 1px, 1px 40px, 8px 8px;
  background-position: center center;
  box-sizing: border-box;
  animation: bike 0.3s linear infinite;
}

.loader::before {
  left: 25px;
  top: 60px;
}

@keyframes bike {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
